import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Carousel from 'react-material-ui-carousel';

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};

const Partners = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {
	const outerClasses = classNames(
		'features-tiles section center-content',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: 'Delivery partners',
		paragraph:
			'HighRank Education is proud to be associated with the following organisations that partner with us to deliver our services.   ',
	};

	let carouselHeight = 200;
	let mediaQuery = window.matchMedia('(max-width: 780px)');

	if (mediaQuery.matches) {
		carouselHeight = 350;
	}

	return (
		<>
			<div className='container'>
				<div className={innerClasses}>
					<SectionHeader
						data={sectionHeader}
						className='center-content'
					/>

					<Carousel
						height={carouselHeight}
						interval={3000}
						{...props}
						className={outerClasses}>
						<div className={tilesClasses}>
							<div className='tiles-item'>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/congnitoryadvisory.jpg')}
												alt='cognitory-house'
												width={150}
												height={70}
											/>
											Cognity Advisory Ltd
										</div>
									</div>
								</div>
							</div>

							<div className='tiles-item'>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/clarity.jpg')}
												alt='clarity'
												width={150}
												height={100}
											/>
											<p>Clarity Education</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={tilesClasses}>
							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/eyef.jpg')}
												alt='EYEF'
												width={100}
												height={50}
											/>
											<p>
												Early Years Education Foundation
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/baf.png')}
												alt='BAF'
												width={140}
												height={50}
											/>
											<p>Bunmi Adedayo Foundation</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={tilesClasses}>
							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/brands.jpeg')}
												alt='Brandstewards'
												width={150}
												height={90}
											/>
											<p>Brand Stewards Limited</p>
										</div>
									</div>
								</div>
							</div>

							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/axiomlearning.jpg')}
												alt='Axiom Learning'
												width={130}
												height={70}
											/>
											<p>Axiom Learning</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={tilesClasses}>
							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/leadlearning.jpg')}
												alt='lead-learning-logo'
												width={160}
												height={100}
											/>
											Leading learning Ltd
										</div>
									</div>
								</div>
							</div>

							<div className='tiles-item '>
								<div className='tiles-item-inner'>
									<div className='features-tiles-item-header'>
										<div className='mb-16'>
											<Image
												src={require('./../../assets/images/nurturehouse.jpg')}
												alt='nurture-House'
												width={170}
												height={80}
											/>
											<p> Nurture House Consulting</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Carousel>
				</div>
			</div>
		</>
	);
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
