import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};

const Testimonial = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {
	const outerClasses = classNames(
		'testimonial section',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'testimonial-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

	const sectionHeader = {
		title: 'Testimonials',
		paragraph:
			"HighRank is proud to have worked with a multitude of schools and Organisations and here's what some of them think about our services",
	};

	return (
		<section {...props} className={outerClasses}>
			<div id='testimonials' className='container mt-0 pt-0'>
				<div className={innerClasses}>
					<SectionHeader
						data={sectionHeader}
						className='center-content'
					/>
					<div className={tilesClasses}>
						<div
							className='tiles-item reveal-from-right'
							data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='testimonial-item-content'>
									<p className='text-sm mb-0 text-color-low'>
										— The Highrank Education’s services are
										unique products of many years of inside
										experiences of visionary educational
										experts, teachers and parents. Their
										ideas and methods have been tested and
										proven to be fresh, brilliantly
										expressed and vital to the kind of
										positive change which schools must
										undergo to be time relevant.
									</p>
								</div>
								<div className='testimonial-item-footer text-xs mt-32 mb-0 has-top-divider'>
									<span className='testimonial-item-name text-color-high'>
										Mrs. Omobola Dauda
									</span>
									<span className='text-color-low'> / </span>
									<span className='testimonial-item-link'>
										<a href='#0'>
											Director, The WestPrime School,
											Ibadan
										</a>
									</span>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='testimonial-item-content'>
									<p className='text-sm mb-0 text-color-low'>
										— Having interacted with HighRank
										education for some years now, I consider
										them a highly professional, and ethical
										organisation with a lot of passion for
										the improvement of education in Nigeria
									</p>
								</div>
								<div className='testimonial-item-footer text-xs mt-32 mb-0 has-top-divider'>
									<span className='testimonial-item-name text-color-high'>
										Mr. Olumide Kamal
									</span>
									<span className='text-color-low'> / </span>
									<span className='testimonial-item-link'>
										<a href='#0'>
											Principal, Bloombreed High School
											Port Harcourt
										</a>
									</span>
								</div>
							</div>
						</div>

						<div
							className='tiles-item reveal-from-left'
							data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='testimonial-item-content'>
									<p className='text-sm mb-0 text-color-low'>
										— HighRank Education’s intervention in
										our schools has undoubtedly added
										globalization to how we do things. The
										organisation focuses on what is relevant
										to turning our students to be 21st
										century compliant.
									</p>
								</div>
								<div className='testimonial-item-footer text-xs mt-32 mb-0 has-top-divider'>
									<span className='testimonial-item-name text-color-high'>
										Mr. Ishola Agbaje
									</span>
									<span className='text-color-low'> / </span>
									<span className='testimonial-item-link'>
										<a href='#0'>
											Director, Bluefield Schools
										</a>
									</span>
								</div>
							</div>
						</div>

						<div
							className='tiles-item reveal-from-left'
							data-reveal-delay='200'>
							<div className='tiles-item-inner'>
								<div className='testimonial-item-content'>
									<p className='text-sm mb-0 text-color-low'>
										— HighRank’s strategy is vital to the
										needs of school administrators and
										teachers. They identify what is lacking
										and create customized products that
										solve real-life problems.
									</p>
								</div>
								<div className='testimonial-item-footer text-xs mt-32 mb-0 has-top-divider'>
									<span className='testimonial-item-name text-color-high'>
										Mr. Mayowa Oyetunji
									</span>
									<span className='text-color-low'> / </span>
									<span className='testimonial-item-link'>
										<a href='#0'>
											Ministry of Education, Osun State
										</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
