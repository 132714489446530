import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';
import SmoothScroll from '../../elements/SmoothScroll';

const Logo = ({ className, ...props }) => {
	const classes = classNames('brand', className);

	const mediaQuery = window.matchMedia('(max-width: 768px)');

	if (mediaQuery.matches) {
		return (
			<div {...props} className={classes}>
				<h1 className='m-0'>
					<SmoothScroll to='home' duration={1000}>
						{' '}
						<Image
							src={require('./../../../assets/images/highrank-logo-modified.png')}
							alt='Open'
							width={60}
							height={60}
							className='pt-8'
						/>
					</SmoothScroll>
				</h1>
			</div>
		);
	}

	return (
		<div {...props} className={classes}>
			<h1 className='m-1'>
				<SmoothScroll to='home' duration={1000}>
					<Image
						src={require('./../../../assets/images/highrank-logo-modified.png')}
						alt='logo'
						width={70}
						height={70}
						align='center'
					/>
				</SmoothScroll>
			</h1>
		</div>
	);
};

export default Logo;
