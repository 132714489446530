import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};
const Gallery = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {
	const outerClasses = classNames(
		'features-tiles section text-sm',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'features-tiles-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	const tilesClasses = classNames(
		'tiles-wrap center-content',
		pushLeft && 'push-left'
	);

	const sectionHeader = {
		title: 'Photo Gallery',
		paragraph: 'View HighRank Education in photos',
	};

	return (
		<section {...props} className={outerClasses}>
			<div id='gallery' className='container'>
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className='center-content' />
					<div className={tilesClasses}>
						<div className='tiles-item reveal-from-bottom text-sm'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/DSC_0190(1).jpg')}
											alt='apen-conference'
											width={300}
											height={300}
										/>
										APEN conference Training at Lagos Business School
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG-20220422-WA0011.jpg')}
											alt='photo-12'
											width={300}
											height={300}
										/>
										Vivian Fowler Memorial School for Girls, Ikeja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/DSC_0222.jpg')}
											alt='photo-2'
											width={300}
											height={300}
										/>
										APEN Award
									</div>
								</div>
							</div>
						</div>
						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/ntiaabuja.JPG')}
											alt='photo-3'
											width={300}
											height={300}
										/>
										National Teachers Institute Training, Abuja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/DSC_5783.JPG')}
											alt='photo-5'
											width={300}
											height={300}
										/>
										NTI Traning, Abuja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG-20220422-WA0019.jpg')}
											alt='photo-13'
											width={300}
											height={300}
										/>
										Vivian Fowler Memorial School for Girls, Ikeja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20220411_151239_255-min.jpg')}
											alt='photo-10'
											width={300}
											height={300}
										/>
										Lagos State Teachers Conference{' '}
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20210616_093948_526.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Lagos TESCOM Induction Programme
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/estiuoes.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Employability Skills Training, Igbinedon University, Okada,
										Edo State
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20210420_125145_830.jpg')}
											alt='photo-7'
											width={300}
											height={300}
										/>
										Leadership Development Training at Bloombreed High School,
										PortHacourt
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG-20180129-WA0005.jpg')}
											alt='photo-14'
											width={300}
											height={300}
										/>
										Award at Youth Arise Conference, Ijebu-Igbo, Ogun State
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20220411_151647_999.jpg')}
											alt='photo-15'
											width={300}
											height={300}
										/>
										Lagos State Teacher Training
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20210421_161729_174.jpg')}
											alt='photo-9'
											width={300}
											height={300}
										/>
										Leadership Development Training (outdoor activities) at
										Bloombreed High School, PortHacourt
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/cnpsta.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Community Nursery and Primary School Training, Asokoro,
										Abuja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/iltoo.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Instructional Leadership Training, Osogbo, Osun State
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/ntit.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										National Teachers Institute Training, Abuja
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/nuestnsm.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										NUT Endwell School Training, Niger State Minna
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/IMG_20220414_132352_859.jpg')}
											alt='photo-11'
											width={300}
											height={300}
										/>
										With Lagos State Star Teachers
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/yatiios.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Youth Arise Training, Ijebu-Igbo, Ogun State
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/0300005.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Leadership Development Training at Daywaterman College, Ogun
										State
									</div>
								</div>
							</div>
						</div>
						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/tfn.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Teach for Nigeria
									</div>
								</div>
							</div>
						</div>

						<div className='tiles-item reveal-from-bottom'>
							<div className='tiles-item-inner'>
								<div className='features-tiles-item-header'>
									<div className='mb-16'>
										<Image
											src={require('./../../assets/images/Gallery/ojttio.jpg')}
											alt='photo-6'
											width={300}
											height={300}
										/>
										Olaoye Jaiyeola Teacher Training Initiative, Okemesi, Ekiti
										State
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

Gallery.propTypes = propTypes;
Gallery.defaultProps = defaultProps;

export default Gallery;
