import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SmoothScroll from '../../elements/SmoothScroll.js';

const FooterNav = ({ className, ...props }) => {
	const classes = classNames('footer-nav', className);

	return (
		<nav {...props} className={classes}>
			<ul className='list-reset'>
				<li>
					<SmoothScroll to='home' duration={1000}>
						Back to Top
					</SmoothScroll>
				</li>
				<li>
					<Link
						to={{
							pathname:
								'https://www.linkedin.com/in/akin-alamu-30a4169/',
						}}
						target='_blank'>
						Connect
					</Link>
				</li>
				<li>
					<Link
						to={{
							pathname:
								'https://highrankeducationblog.blogspot.com/',
						}}
						target='_blank'>
						Blog
					</Link>
				</li>
				<li>
					<Link
						to={{
							pathname:
								'https://highranknewsletter.herokuapp.com/',
						}}
						target='_blank'>
						Subscribe
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default FooterNav;
