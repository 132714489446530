import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Partners from '../components/sections/Partners';
import Gallery from '../components/sections/Gallery';

const Home = () => {
	return (
		<>
			<Hero className='illustration-section-01' />
			<FeaturesSplit
				invertMobile
				topDivider
				imageFill
				className='illustration-section-02'
			/>
			<FeaturesTiles />
			<Partners />
			<Testimonial />
			<Gallery topDivider />
			<Cta split />
		</>
	);
};

export default Home;
