import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section about-us',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'About Us',
    paragraph: ''
  };

  const ceoHeader = {
    title: 'Head Consultant',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div id='about-us' className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  21st Century approach
                  </div>
                <h3 className="mt-0 mb-12">
                  Who we are
                  </h3>
                <p className="m-0">
                We are education development and management consultants with expertise in teacher engagement, development, re-orientation and school management support. We identify gaps in skills and competencies of schools/professionals and develop bespoke products to fill these gaps for the benefits of all stakeholders especially the learners.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className= "has-shadow"
                  src={require('./../../assets/images/features-split-image-01.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  21st Century approach
                  </div>
                <h3 className="mt-0 mb-12">
                  Our Philosophy
                  </h3>
                <p className="m-0">
                Our way of life at HighRank is thinking outside the box. We develop products that challenge mediocrity and long-held unprofessional practice in order to meet the rapidity and dynamism of changes in today’s world. We are consistently creating products that have the potential to positively transform the world.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className= "has-shadow"
                  src={require('./../../assets/images/features-split-image-02.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={ceoHeader} className="center-content mb-0" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  21st Century LEADERSHIP
                  </div>
                <h3 className="mt-0 mb-12">
                  Akin Alamu
                  </h3>
                <p className="m-0">
                Akin Alamu is an education sector development and management specialist with considerable multi-sectoral experience spanning project/strategy planning and management; school planting and management; curriculum development and evaluation; teacher engagement/development and evaluation of educational programmes. He has been in active practice in education and professional training related business for over three decades running. He consults for governments, international organisations and high profile schools within and outside Nigeria.
                  </p>
              <div className="reveal-from-bottom mt-8" data-reveal-delay="600">        
                   <a href='https://highrankeducationblog.blogspot.com/2022/05/profile-of-akin-alamu-head-consultant.html'>
                   <Button tag="a" color="primary"  wideMobile> Read More
                   </Button>
                     </a> 
              </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className= "has-shadow"
                  src={require('./../../assets/images/akinalamu.jpg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
       
    </section>

  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;