import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Typography from '@mui/material/Typography';
import ButtonGroup from '../elements/ButtonGroup';

const propTypes = {
	...SectionProps.types,
};

const defaultProps = {
	...SectionProps.defaults,
};

const Hero = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	...props
}) => {
	// const [videoModalActive, setVideomodalactive] = useState(false);

	// const openModal = (e) => {
	//   e.preventDefault();
	//   setVideomodalactive(true);
	// }

	// const closeModal = (e) => {
	//   e.preventDefault();
	//   setVideomodalactive(false);
	// }

	const outerClasses = classNames(
		'hero section center-content hero-img home',
		topOuterDivider && 'has-top-divider',
		bottomOuterDivider && 'has-bottom-divider',
		hasBgColor && 'has-bg-color',
		invertColor && 'invert-color',
		className
	);

	const innerClasses = classNames(
		'hero-inner section-inner',
		topDivider && 'has-top-divider',
		bottomDivider && 'has-bottom-divider'
	);

	return (
		<section {...props} className={outerClasses}>
			<div id='home' className='container-sm '>
				<div className={innerClasses}>
					<div className='hero-content'>
						<h1
							className='mt-0 mb-16 reveal-from-bottom t'
							data-reveal-delay='200'>
							HighRank Education <span>Consulting</span>
						</h1>
						<div className='container-xs'>
							<p
								className='m-0 mb-32 reveal-from-bottom'
								data-reveal-delay='400'>
								<Typography variant='body3' className='dot'>
									Curriculum Development{' '}
									<span className='dot'> </span>
								</Typography>
								<Typography variant='body3' className='dot'>
									&middot; Teacher Engagement{' '}
									<span className='dot'> </span>
								</Typography>
								<Typography variant='body3' className='dot'>
									&middot; School Management Support{' '}
									<span className='dot'> </span>
								</Typography>
								<Typography variant='body3' className='dot'>
									&middot; Life skills
								</Typography>
								<Typography variant='body3' className='dot'>
									&middot; School Brand Support{' '}
									<span className='dot'> </span>
								</Typography>
							</p>
							<div
								className='reveal-from-bottom'
								data-reveal-delay='600'>
								<ButtonGroup>
									<Button
										tag='a'
										color='primary'
										wideMobile
										href='https://br6uvugpnq5.typeform.com/to/nJ7mCCUp'>
										Request a proposal
									</Button>
									<Button
										className='mail-button'
										tag='a'
										color='dark'
										wideMobile
										href='https://highranknewsletter.herokuapp.com'>
										Join our Mailing List
									</Button>
								</ButtonGroup>
							</div>
						</div>
					</div>
					<div
						className='hero-figure reveal-from-bottom illustration-element-01'
						data-reveal-value='20px'
						data-reveal-delay='800'>
						<Image
							className='has-shadow'
							src={require('./../../assets/images/hero.jpeg')}
							alt='Hero'
							width={900}
							height={700}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
